import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
//import { Link } from 'react-router-dom';
import { Section6, Section15, SectionBlock } from '../../../../themes/nerdify/components/Section';
import Holder from './../../../../themes/nerdify/components/Holder';
import { Nerds2 } from '../../../../themes/nerdify/components/Nerds';
import { TextBack8 } from '../../blocks/TextBack';
import { Intro3 } from '../../../../themes/nerdify/components/Intro';
import { Hero7 } from '../../../../themes/nerdify/components/Hero';
import { Header1, HeaderIcon } from '../../../../themes/nerdify/components/Header/index';
import { Footer2 } from '../../blocks/Footer';
import { HnavMob1 } from '../../blocks/HnavMob';
import { Hnav3 } from "../../blocks/Hnav";

//import { InputSelectLocales } from './../../../../themes/nerdify/components/Form/InputSelect';
import LocalesPage from './../LocalesPage';
import { TopNerdsInfo3 } from "../../../../themes/nerdify/components/TopNerdsInfo";
import DemoSubjects from "../../../../themes/nerdify/components/DemoSubjects";
import {useMobile} from "../../../../hooks";

import Text from '../../../../base_components/Text';
import ReviewSimple from "../../../../themes/nerdify/components/ReviewSimple";

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
};

export default class AboutUs extends Component {
  render() {
    const { pageCfg } = this.props;
    return (
      <WithHook>
        {({
            isMobile
          }) => {

    return (
      <LocalesPage {...this.props}>
      <div className="about-us">
        <Header1>
          <Holder>
            <NavLink className='logo-link' to='/'>
              <HeaderIcon iconName='logo-light' theme='light'/>
            </NavLink>
            {isMobile ? <HnavMob1 /> : <Hnav3 theme='light' />}
          </Holder>
        </Header1>

        <Hero7 key='hero5'>
          <Holder className={'holder_3'}>
            <h1 className="hero__title">Nerdify is<br className="mobile-only"/> designed for people aiming to live a balanced life</h1>
          </Holder>
        </Hero7>

          <Intro3 indent={'pt40m pb60m pt60d pb30d'}>
            <Holder className={'holder_3'}>
              <p className="h6">Nerdify is a platform that provides access to 1000s of experienced Nerd Tutors who can help with almost any subject, explain hundreds of topics and share their best practices.</p>
              <p className="h6">It was founded in September 2015 by 2 LSE graduates — Alex and Brian. One day they decided to create a simple service enabling them to connect to graduates with deep expertise in various fields. It all started as a tiny university project for their friends and classmates, but soon it went viral. Nerdify grew into one of the most popular AI-powered platforms providing outstanding support with requests like college-level tutoring, research mentoring, business coaching and more. Now, our Nerd tutors help tens of thousands of students, businesses and academia all over the world!</p>
            </Holder>
          </Intro3>

          <Section6>
            <Holder className={'holder_1'} indent={'pt30m pb30m pt40d pb40d'}>
              <h2 className='h3'>Our mission</h2>
              <p className='p'>              
                We aim to help students, businesses and academia focus on the most important things:<br className="desktop-only"/> getting a degree, achieving success and pursuing their career goals. We want Nerdify<br className="desktop-only"/> to become your time-saving and energy-freeing sidekick.
              </p>
            </Holder>
          </Section6>

        <Section15>
          <Holder>
            <TopNerdsInfo3 titleTag={'h2'} isWide={pageCfg.topNerds?.isWide} texts={pageCfg.topNerds?.texts} />

            <DemoSubjects title={pageCfg.demoSubjects?.title}  />

            <Nerds2 indent={'pt50 pb40'}>
              <div className='nerds__title'>...from some of the <span className="strong">TOP universities</span></div>
            </Nerds2>
          </Holder>
        </Section15>


        <Holder className="mb60">
          <hr className="hr" />
        </Holder>

        <ReviewSimple className="pb50m pb80d" />

        <SectionBlock theme={2} indent="pb80m pb70d">
          <Holder className="holder_3">
            <div className='section-title'>
              {pageCfg.textback?.title ?? <Text>…or send your personal help request<br className="desktop-only" /> and we\'ll text you<br className="mobile-only" /> back in 10 sec</Text>}
            </div>
            <TextBack8 {...this.props} submitBtnTxt={'Text me now'} />
          </Holder>
        </SectionBlock>

        <Footer2 />
      </div>
      </LocalesPage>
    )
        }}
      </WithHook>
    )
  }
}
