import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';

import {
  REACT_APP_CONTACT_EMAIL,
} from './../../../../config';

export default (props) => (
  <LocalesPage {...props}>
    <TextPage>
      <Holder className="holder_1">
        <h1 className="h1">Refund Policy</h1>
        <p className="h6">Nerdify connects a User and a Freelancer to provide particular services that include but are not limited to providing tutoring, holding consultations, preparing learning materials, helping with data entry and data collection, organizing surveys, mentoring, etc.</p>

        <div className="textPage-item">
          <h2 className="h3">What should I do if the service did not meet my initial requirements?</h2>
          <p className="p">All refund requests are to be submitted within 60 days after the service was provided to the User. If the received service did not meet your initial requirements - let us know ASAP. You can send us a message with your order number and comments to:</p>
          <ul className='ul'>
            <li>Email: <a className='a' href={'mailto:' + REACT_APP_CONTACT_EMAIL} target='_blank' rel='noopener noreferrer'>{REACT_APP_CONTACT_EMAIL}</a></li>
          </ul>
        </div>

        <div className="textPage-item">
          <h2 className="h3">I’ve sent a message. What’s next?</h2>
          <p className="p">It will take up to 3-5 business days to review your request. Upon further agreement with you, we will either find another Freelancer who will schedule new session with you, or we will make a refund to your card or balance. Balance money on your Nerdify Account (virtual bonus money used by Nerdify) is non-refundable. Nerdify will need at least 24 hours to find a different Freelancer and schedule a new session.</p>
        </div>
        <div className="textPage-item">
          <h2 className="h3">Refund of your subscription plan</h2>
          <p className="p">You can request a refund for the subscription fee charged on your card up to 30 days from the date of the latest payment. The refund can be issued only if you did not use your bonuses and perks included in the subscription plan since the last payment. In this case, your refund request will be processed within 24 hours and you'll get your money back to the card within 3-5 business days. Please be aware that the bonuses and perks included in your subscription plan are non-refundable and will be withdrawn on the date of the refund issued.</p>
        </div>
      </Holder>
    </TextPage>
  </LocalesPage>
)
