import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Hero3 } from '../../../../themes/nerdify/components/Hero';
import { Header1, HeaderIcon } from './../../../../themes/nerdify/components/Header';

import { HIWItemsGoogleFriendly as HIWItems } from '../../blocks/Hiw';
import { Hiw2, HiwImg, HiwItem, HiwList, HiwText } from '../../../../themes/nerdify/components/Hiw';
import { Nerds2 } from '../../../../themes/nerdify/components/Nerds';
import Holder from './../../../../themes/nerdify/components/Holder'
import { Section4, Section15 } from '../../../../themes/nerdify/components/Section';
import { Footer2 } from '../../blocks/Footer'
import { TextBack2 } from '../../blocks/TextBack'
import { HnavMob1 } from '../../blocks/HnavMob';
import { Hnav3 } from "../../blocks/Hnav";

import LocalesPage from './../LocalesPage';
import {TopNerdsInfo3} from "../../../../themes/nerdify/components/TopNerdsInfo";
import DemoSubjects from "../../../../themes/nerdify/components/DemoSubjects";
import {Intro4} from "../../../../themes/nerdify/components/Intro";
import ReviewSimple from "../../../../themes/nerdify/components/ReviewSimple";
import SliderCombined from "../../../../themes/nerdify/components/SliderCombined";
import Text from "../../../../base_components/Text";
import { SliderCombinedItems_google_friendly as SliderCombinedItems } from '../../blocks/SliderCombined';
import {useMobile} from "../../../../hooks";

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
};

export default class Landing extends Component {
  render () {
    const { pageCfg } = this.props;
    return (
      <WithHook>
        {({
            isMobile
          }) => {
    return <LocalesPage {...this.props}>
      <Header1>
        <Holder>
          <NavLink className='logo-link' to='/'>
            <HeaderIcon iconName='logo-light' theme='light'/>
          </NavLink>
          {isMobile ? <HnavMob1 /> : <Hnav3 theme='light'/>}
        </Holder>
      </Header1>

      <Hero3 key='hero3'>
        <h1 className='h0 hero__title'>How does it all work?</h1>        
        <div className='h5 hero__subtitle'>It's easier than you think!<br/>Simply text us, and our automated algorithm will find the best-matching freelance Nerd Tutor in your subject to explain the topic.</div>
      </Hero3>

      <Hiw2 indent={"pt60 pb60"}>
        <Holder className={"holder_3"}>
          <HiwList>
            {HIWItems.map((item, index) => {
              return (
                <HiwItem key={`hiwItem${index}`}>
                  <HiwImg img={item.img} />
                  <HiwText>
                    <div className='h5'>{item.step}</div>
                    <div className='p'>{item.message}</div>
                  </HiwText>
                </HiwItem>
              )
            })}
          </HiwList>
        </Holder>
      </Hiw2>

      <Holder className={"holder_3"}>
        <hr className="hr hr_1"/>
      </Holder>

      <Section15>
        <div className="holder">
          <TopNerdsInfo3 titleTag={"h2"} isWide={pageCfg.topNerds?.isWide} texts={pageCfg.topNerds?.texts} />
          <DemoSubjects title={pageCfg.demoSubjects?.title} />
          <Nerds2 indent={'pt50 pb40'}>
            <div className='nerds__title'>...from some of the <span className="strong">TOP universities</span></div>
          </Nerds2>
        </div>
      </Section15>

      <div className="hr_6" />

      <Intro4 indent={"pt30 pb50m pb80d"}>
        <Holder className={"holder_3"}>
          <div className="intro__title">Disclaimer</div>
          <div className="intro__msg">
            {(pageCfg.disclaimers && pageCfg.disclaimers[0]) ?? <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any policy or our Honor Code. Please read our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink> and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>}
          </div>
        </Holder>
      </Intro4>

      <ReviewSimple
        className="pb50m pb80d"
      />

      <SliderCombined indent={"pb60"} theme={1}
        titleTag={"h2"}
        title={ pageCfg.sliderCombined?.title ?? (<Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular requests!</Text>)}
        items={ SliderCombinedItems }
      />

      <Section4>
        <Holder>
          <h2 className='h1'>Still doubting whether you need a Nerd Tutor?</h2>
          <p className='h5'>Message us to learn more about what topics Nerd Tutors can help with!</p>
          <TextBack2 submitBtnTxt={'Text me now'} />
        </Holder>
      </Section4>

      <Footer2 />
    </LocalesPage>
        }}
      </WithHook>
    )
  }
}
