import React from "react";
import Text from "../../../../base_components/Text";
import { Link } from 'react-router-dom'

export const FAQItems = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "What requests can a Nerd help me with?",
    A: <Text>Our Nerds can help you with any type of request except for those that are illegal or violate university and corporate policies. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your request by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your request! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work with you thoroughly.</Text>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
];

export const FAQItems_google_friendly = [
  {
    Q: "Who are the Nerd Tutors?",
    A: <Text>Nerd Tutors are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to explain you any topic in any subject. Every Nerd Tutor agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "Can a Nerd Tutor help me with my task?",                 
    A: <Text>Our Nerd Tutors can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd Tutor will refuse to do your task for you. Instead, a Nerd Tutor can help you find materials for the research, explain the complex topic, advise on how to structure your task, or provide other learning tips. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</Text>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for the tutoring session of your Nerd. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it's up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the tutoring session is scheduled, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
];

export const FAQItems2 = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my homework?",
    A: <Text>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your homework. Instead, a Nerd can help you find materials, explain how to solve the homework problem, elaborate on the topic, or advise on how to structure your research. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</Text>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQItems3 = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "What requests can a Nerd help me with?",
    A: <Text>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies.</Text>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_students_1 = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my essay?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your essay. Instead, a Nerd can help you find materials for the research, explain the essay topic, advise on how to structure your essay, or provide other writing tips. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_students_2 = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my paper?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your paper. Instead, a Nerd can help you find materials for the research paper, explain the paper topic, advise on how to structure your paper, or provide other writing tips. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_students_3 = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my homework?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your homework. Instead, a Nerd can help you find materials, explain how to solve the homework problem, elaborate on the topic, or advise on how to structure your research. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_students_4 = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my assignment?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your assignment. Instead, a Nerd can help you find materials for the assignment, explain the topic or advise you on how to structure your research. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_students_5 = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my homework?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your homework. Instead, a Nerd can help you find materials for the research report or project, explain the topic of the discussion or advise on how to structure your speech. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_students_6 = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my writing task?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your writing task. Instead, a Nerd can help you find materials for the research report or project, explain the topic of the discussion, provide excel explanations, advise on how to structure your speech or annotated bibliography. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_math_help = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my Math homework?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your homework. Instead, a Nerd can help you find materials, explain how to solve the homework problem, elaborate on the Math topic or advise on how to structure your research. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_statistics_help = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my Statistics homework?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your homework. Instead, a Nerd can help you find materials for the research, explain how to solve the homework problem, elaborate on the Statistics topic or advise on your calculations. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_natural_sciences_help = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my Science homework?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your homework. Instead, a Nerd can help you find materials for Science research, explain how to solve your Biology problem, elaborate on the Physics or Chemistry topic, and advise on your lab report. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_business_help = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my Business homework?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your homework. Instead, a Nerd can help you find materials for Marketing research, explain the Business topic or advise on your Management task. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_social_sciences_help = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my homework?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your homework. Instead, a Nerd can help you find materials for the Sociology research, explain the Psychology topic, or advise on your Law task. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_finance_accounting_help = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my Finance homework?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your homework. Instead, a Nerd can help you find materials for Financial research, explain the Economics topic, or advise on your Accounting calculations. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_humanities_help = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my Humanities homework?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your homework. Instead, a Nerd can help you find materials for Nursing research, explain the Philosophy topic, or advise on your Anthropology task. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

export const FAQ_english_literature_help = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Every Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and work on your request thoroughly.</Text>
  },
  {
    Q: "Can a Nerd help with my homework?",
    A: <>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. So, a Nerd will refuse to do your homework. Instead, a Nerd can help you find materials for an English research, explain a Linguistics topic, or advise on how to structure your Literature review. Read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. You'll always agree to the final cost before any charges are made! So, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>Once the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data.</Text>
  },
  {
    Q: "How do you find Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];

