import React from 'react';

import trustpilot from './trustpilot-4-goog-fr.json';
import sitejabber from './sitejabber-4-goog-fr.json';
import facebook from './facebook-4-goog-fr.json';
import google from './google-4-goog-fr.json';

import ReviewBlock from '../../../../themes/nerdify/components/ReviewBlock';

const items = {
  trustpilot,
  sitejabber,
  facebook,
  google,
};

const Reviews = () => {
  return <ReviewBlock items={items} />;
};

export default Reviews;
