import React from "react";

export const demoChatItems = [
  { text: "Don't understand my task 😱", type: "user" },
  { text: "Just drop a pic of a part you are stuck on 📸", type: "nerd" },
  { type: "img" },
  { text: "Have a class on Monday 🙏", type: "user" },
  { text: "Got it 👌 One of our top History Nerds is ready to clarify it for you asap!", type: "nerd" }
];

export const demoChatItems2 = [
  { text: <>Hey! I need some research done<br/> on my company’s competitors.</>, type: "user" },
  { text: "Sure! Just drop me a pic with all details 📸", type: "nerd" },
  { type: "img" },
  { text: "Got it 👌 One of the top Nerds is ready to start working on it asap!", type: "nerd" }
];

export const demoChatItems3 = [
  { text: <React.Fragment>Don't understand my task</React.Fragment>, type: 'user' },
  { text: 'Just drop a pic of a part\nyou are stuck on 📷', type: 'nerd' },
  { type: "img" },
  { text: 'Have a class on Monday 🙏', type: 'user' },
  { text: 'Got it 👌 One of our top History Nerds is ready to clarify it for you asap!', type: 'nerd' }
];

export const demoChatItems4 = [
  { text: <React.Fragment>I don't have time to find sources<br/> for my paper. Can you help?</React.Fragment>, type: 'user' },
  { text: 'Sure! You can drop a pic of your task instructions right here 😊', type: 'nerd' },
  { type: 'img' },
  { text: 'Have a class on Monday 🙏', type: 'user' },
  { text: 'Got it 👌 One of our Top Nerds is ready to help you asap!', type: 'nerd' }
];

export const demoChatItems5 = [
  { text: "Don't understand my topic 😱", type: "user" },
  { text: "Just tell us what subject do you need help with 📚", type: "nerd" },
  { text: "Have History class on Monday", type: "user" },
  { text: "Got it 👌 One of our top History Nerds is ready to schedule a tutoring session with you asap!", type: "nerd" }
];

export const demoChatForBuissinessItems = [
  { text: <React.Fragment>I need someone to make<br/> a SWOT analysis.</React.Fragment>, type: 'user' },
  { text: <React.Fragment>Sure! Please share<br/> the details.</React.Fragment>, type: 'nerd' },
  { type: 'img' },
  { text: 'Gotcha! I found one of our best Marketing Nerds who\'s ready to get started asap.', type: 'nerd' }
];

export const demoChatForBuissinessItemsGoogleFriendly = [
  { text: <React.Fragment>I need someone to help<br/> with a SWOT analysis.</React.Fragment>, type: 'user' },
  { text: <React.Fragment>Gotcha, please share <br/>the details.</React.Fragment>, type: 'nerd' },
  { text: <React.Fragment>I've stuck on threats<br/> and can't evaluate<br/> them correctly.</React.Fragment>, type: 'user' },
  { text: 'Gotcha! I found one of our best Marketing Nerds who\'s ready to schedule a tutoring session asap.', type: 'nerd' }
];

export const demoChatForAcademiaItems = [
  { text: <React.Fragment>I want to publish my article and<br/> need a list of relevant media.</React.Fragment>, type: 'user' },
  { text: <React.Fragment>Gotcha, please drop me<br/> a pic with details</React.Fragment>, type: 'nerd' },
  { type: 'img', text: 'case1.jpg' },
  { text: <React.Fragment>The Nerd will provide it<br/> in the next 12 hours!</React.Fragment>, type: 'nerd' }
];

export const demoChatForAcademiaGoogleFriendly = [
  { text: <React.Fragment>My teaching assistant got sick<br/> and I need someone to help me<br/> answer students' questions on<br/> metal fatigue in aircraft<br/> structures during online<br/> seminar this Thursday.</React.Fragment>, type: 'user' },
  { text: <React.Fragment>We have a Nerd Tutor with Masters degree in Aircraft Body and Engine Maintenance. What time is your seminar?</React.Fragment>, type: 'nerd' },
  { text: <React.Fragment>2 pm MST</React.Fragment>, type: 'user' },
  { text: <React.Fragment>The session is scheduled!</React.Fragment>, type: 'nerd' }
];
