import React, { Component } from 'react'
import Holder from './../../../../themes/nerdify/components/Holder';
import Icon from './../../../../themes/nerdify/components/Icons';
import {Link, NavLink} from 'react-router-dom';
import Scroll from 'react-scroll';

import TextPage from './../TextPage';

import { Section4 } from '../../../../themes/nerdify/components/Section';
import {TextBack2} from '../../blocks/TextBack'

import { Intro1, Intro4 } from '../../../../themes/nerdify/components/Intro';
import { NerdImg } from '../../../../themes/nerdify/components/TopNerds';

import '../../../../themes/nerdify/components/TopNerds/index.css';
import Text from '../../../../base_components/Text';

import { trackClicks } from '../../../../lrtracker';
import LocalesPage from './../LocalesPage';

const PAGE_SIZE = 10;

const nerdsList = [
  {
    name: 'James S.',
    rating: 4.5,
    requests: 203,
    degree: 'Master’s',
    major: 'Business Administration',
    competences: 'Marketing Strategy, Market Research, Advertising',
    img: '1_James_S'
  },
  {
    name: 'Nick P.',
    rating: 5,
    requests: 418,
    degree: 'Bachelor',
    major: 'English',
    competences: 'English, British English, Creative Writing, Web Content',
    img: '2_Nic_P'
  },
  {
    name: 'Jessica L.',
    rating: 4.5,
    requests: 182,
    degree: 'Bachelor',
    major: 'Marketing',
    competences: 'Internet Marketing, Creative Writing, Social Media Marketing',
    img: '3_Jessika_L'
  },
  {
    name: 'Kate N.',
    rating: 5,
    requests: 198,
    degree: 'Student',
    major: 'English',
    competences: 'English, Press Releases',
    img: '4_Kate_N'
  },
  {
    name: 'Katty P',
    rating: 4.5,
    requests: 156,
    degree: 'Bachelor',
    major: 'Foreign Languages',
    competences: 'Italian, Spanish, English, Translation, Article Writing, Editing & Proofreading',
    img: '5_Katty_P'
  },
  {
    name: 'Patay L.',
    rating: 4.5,
    requests: 352,
    degree: 'Master’s',
    major: 'Business Administration',
    competences: 'Marketing Strategy, Social Media Marketing, SMM',
    img: '6_Patay_L'
  },
  {
    name: 'James R.',
    rating: 5,
    requests: 201,
    degree: 'PhD',
    major: 'Business Administration',
    competences: 'Market Research, Lead Generation',
    img: '7_James_R'
  },
  {
    name: 'Lucy T.',
    rating: 4.5,
    requests: 98,
    degree: 'Student',
    major: 'Computer Science',
    competences: 'Landing Pages, HTML, Laravel Framework',
    img: '8_Luccy_T',
    horisontalPhoto: true,
  },
  {
    name: 'Thomas M.',
    rating: 5,
    requests: 276,
    degree: 'Bachelor',
    major: 'Economics',
    competences: 'Tutoring, Investment Research, Financial Analysis',
    img: '9_Tomas_M'
  },
  {
    name: 'William F.',
    rating: 4.5,
    requests: 71,
    degree: 'Bachelor',
    major: 'Human Resource Management',
    competences: 'Human Resources, Labor Code, Legal Writing',
    img: '10_William_F'
  },
  {
    name: 'Kiaan T.',
    rating: 5,
    requests: 131,
    degree: 'Bachelor',
    major: 'Accounting',
    competences: 'Tutoring, Financial Analysis, Finances',
    img: '11_Kiaan_T'
  },
  {
    name: 'Lee L.',
    rating: 4.5,
    requests: 287,
    degree: 'Student',
    major: 'Business Administration',
    competences: 'Marketing Strategy, Human Resources',
    img: '12_Lee_L'
  },
  {
    name: 'Monica B.',
    rating: 5,
    requests: 57,
    degree: 'Student',
    major: 'Computer Science',
    competences: 'Tutoring, Math, Physics, Computering',
    img: '13_Monica_B',
    horisontalPhoto: true,
  },
  {
    name: 'Sandra O.',
    rating: 4.5,
    requests: 175,
    degree: 'Master’s',
    major: 'Business Administration',
    competences: 'Human Resources, Team Strategy, HR Strategy',
    img: '14_Sandra_O',
    horisontalPhoto: true,
  },
  {
    name: 'Killiam M.',
    rating: 5,
    requests: 402,
    degree: 'Bachelor',
    major: 'Communications',
    competences: 'Market Research, Creative Writing, Communications',
    img: '15_Killiam_M'
  },
  {
    name: 'Lakshit R.',
    rating: 4.5,
    requests: 511,
    degree: 'Master’s',
    major: 'Philosophy',
    competences: 'Article Writing, Business Writing',
    img: '16_Lakshit_R'
  },
  {
    name: 'Bob O.',
    rating: 5,
    requests: 194,
    degree: 'Bachelor',
    major: 'Computer Science',
    competences: 'HTML, Math, Physics, Modeling',
    img: '17_Bob_O'
  },
  {
    name: 'Air C.',
    rating: 4.5,
    requests: 76,
    degree: 'Student',
    major: 'Biology',
    competences: 'Tutoring, Blog Writing, Healthcare',
    img: '18_Ai_C'
  },
  {
    name: 'Samuel L.',
    rating: 4.5,
    requests: 312,
    degree: 'PhD',
    major: 'English',
    competences: 'Article Writing, Social Media Marketing, English, Editing & Proofreading',
    img: '19_Samuel_L'
  },
  {
    name: 'Victor A.',
    rating: 5,
    requests: 509,
    degree: 'Bachelor',
    major: 'Business Administration',
    competences: 'Marketing Strategy, Tutoring, Business Writing',
    img: '20_Victor_A'
  },
];

const Nerd = ({ item }) => (
  <div className="nerds-item nerds-item__show">
    <div className="nerds-item__photo">
      <NerdImg item={item} />
    </div>

    <div className="nerds-item__info">
      <div className="p nerds-item__info__name">{item.name}</div>
      <div className="nerds-item__rating">
        <Icon iconName="star-orange" className="icon-star-20" />
        <Icon iconName="star-orange" className="icon-star-20" />
        <Icon iconName="star-orange" className="icon-star-20" />
        <Icon iconName="star-orange" className="icon-star-20" />
        {item.rating === 5 &&
          <Icon iconName="star-orange" className="icon-star-20" />
        }
        {item.rating !== 5 &&
          <Icon iconName="star-orange-half" className="icon-star-20" />
        }
      </div>

      <div className="nerds-item__info__top">
        <div className="nerds-item__info__desc-wrap">
          <div className="p p__small p__darker nerds-item__info__desc">
            <Icon iconName="papers" className="icon-papers nerds-item__icon" />
            Tutoring sessions held: <strong>{item.requests}</strong>
          </div>
          <div className="p p__small p__darker nerds-item__info__desc">
            <Icon iconName="hat" className="icon-hat nerds-item__icon" />
            Degree: <strong>{item.degree}</strong>
          </div>
          <div className="p p__small p__darker nerds-item__info__desc">
            <Icon iconName="check-round" className="icon-check nerds-item__icon" />
            Major: <strong>{item.major}</strong>
          </div>
        </div>
      </div>

      <div className="p p__title nerds-item__title">Competences:</div>
      <p className="p p__small p__darker nerds-item__competences">{item.competences}</p>
      <Scroll.Link to="form" className="button button__type10 nerds-item__btn" smooth offset={-120}>Schedule a session</Scroll.Link>
    </div>
  </div>
)

class TopNerds extends Component {

  state = {
    page: 1
  };

  onNextPage = () => {
    trackClicks('target', 'showMoreNerds', {context: {}})();
    this.setState({ page: this.state.page + 1 })
  };

  render() {
    const { pageCfg } = this.props;
    const nerds = nerdsList.slice(0, this.state.page * PAGE_SIZE)
    const isShowNextPage = nerdsList.length > nerds.length

    return (
      <LocalesPage {...this.props}>
      <TextPage className="topNerds" noWrap>
        <Intro1>
          <Holder className="holder_1">
            <h1 className="h1">Meet TOP Nerd Tutors</h1>
            <div className="h6">Need to schedule an on-demand tutoring session ASAP? Trust the experience of 1000s of freelance Nerd Tutors in our network and let our algorithm find the one who is perfect for your topic!</div>
          </Holder>
        </Intro1>

        <Holder className="holder_1">
          <div className="nerds-list">
            {nerds.map(nerd => <Nerd item={nerd} key={nerd.name} />)}

            {isShowNextPage &&
              <button
                className="button button__type9 top-nerds__show-more"
                onClick={this.onNextPage}
              >
                Show more Nerds
              </button>
            }
          </div>
        </Holder>

        <Intro4 indent={"pt60d pb60d pt20m pb40m"}>
          <Holder className={"holder_3"}>
          <div className="intro__title">Disclaimer</div>
          <div className="intro__msg">
            {(pageCfg.disclaimers && pageCfg.disclaimers[0]) ?? <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any policy or our Honor Code. Please read our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink> and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>}
          </div>
          </Holder>
        </Intro4>

        <Section4>
          <Holder>
            <h2 className='h1' id={'form'}>Still doubting whether you need a Nerd Tutor?</h2>
            <div className="h5">Message us to learn more about what topics Nerd Tutors can help with!</div>
            <TextBack2 submitBtnTxt={'Text me now'} />
          </Holder>
        </Section4>

        <Holder className="holder_1" indent={'pt40m pt60d'}>
          <div className="top-nerds__banner">
            <div className="top-nerds__banner__pic" />
            <div className="top-nerds__banner__title-group">
              <div className="h4 h4__custom top-nerds__banner__title">Wanna become a member of a Nerdy network?</div>
              <div className="p p__custom top-nerds__banner__subtitle">Apply to become a Nerd Tutor in a few easy steps.</div>
            </div>
            <Link className="button button__type6 top-nerds__banner__lnk" to="/become">Become a Nerd Tutor</Link>
          </div>
        </Holder>

      </TextPage>
      </LocalesPage>
    )
  }
}

export default TopNerds
