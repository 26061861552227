import React, {Fragment} from 'react';
import classnames from "classnames";
import Holder from './../Holder';
import './index.css';
import imgRecommendActive from './img/recommend-active.png';
import imgEarth from './img/earth.png';


const reviews = [
  {
    name: "Ester J.",
    date: "3 days ago",
    photo: "EsterJoan.jpg",
    photo2x: "EsterJoan@2x.jpg",
    review: "Nerdify's support team is simply outstanding. They've been there every step of the way, providing excellent tutoring service and making my learning journey enjoyable."
  },
  {
    name: "Harold S.",
    date: "2 weeks ago",
    photo: "HaroldSimmons.jpg",
    photo2x: "HaroldSimmons@2x.jpg",
    review: "I can't recommend Nerdify enough. Their services have been a cornerstone of my study success. They provide excellent tutoring, friendly customer support, and all the necessary support during learning."
  },
  {
    name: "Maya M.",
    date: "6 days ago",
    photo: "MayaMichael.jpg",
    photo2x: "MayaMichael@2x.jpg",
    review: "I've tried other tutoring services in the past, but none of them compare to Nerdify. They are dedicated to what they do and really care about their students. I plan on using this platform during all my college time!"
  },
  {
    name: "Rosa B.",
    date: "1 month ago",
    photo: "RosaBishop.jpg",
    photo2x: "RosaBishop@2x.jpg",
    review: "A friend gave me a hint to try this service, and I've been using it ever since. I know that when I'm in a crunch the nerd tutors will help for sure. Thanks to Nerdify and their assistance my academic results have significantly improved."
  },

];

export default ({ className, title }) => {
  return (
    <div className={classnames('reviews_simple', className)}>
      <Holder>
        <h2 className="title">{title || 'Check Nerdify Reviews'}</h2>
        <div className="container">
          {reviews.map((item, key) => {
            return (
              <div className="card" key={`card_${key}`}>
                <div className="header">

                  <img
                    loading="lazy"
                    className="avatar"
                    src={require(`./photos/${item.photo}`)}
                    srcSet={`${require(`./photos/${item.photo}`)} 1x${item.photo2x ? ', ' + require(`./photos/${item.photo2x}`) + ' 2x': ''}`}
                    width="38"
                    height="38"
                    alt=""
                  />
                  <div className="headerContent">
                    <div className="headerNameRow">
                      <span className="headerName headerBold">{item.name}</span>
                      <img
                        loading="lazy"
                        className="imgRecommend"
                        src={imgRecommendActive}
                        width="16"
                        height="15"
                        alt=""
                      />
                      <span className="headerName">recommends <span className="headerBold">Nerdify</span></span>
                    </div>
                    <div className="headerDate">
                      {item.date}
                      {' • '}
                      <img
                        loading="lazy"
                        className="imgEarth"
                        src={imgEarth}
                        width="12"
                        height="12"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="text">{item.review}</div>
              </div>
            );
          })}
        </div>
      </Holder>
    </div>
  );
};

