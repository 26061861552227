import React from 'react';

import Text from '../../base_components/Text';
import { NavLink, Link } from 'react-router-dom';

import Landing from './pages/Landing';
import Landing7 from './pages/Landing7';
import LandingForBusinesses from './pages/LandingForBusinesses';
import LandingForAcademia from './pages/LandingForAcademia';

import About from './pages/About';
import Become from './pages/Become';
import Cookies from './pages/Cookies';
import HonorCode from './pages/HonorCode';
import HowItWorks from './pages/HowItWorks';
import Maintenance from './pages/Maintenance';
import NerdifyReviews from './pages/NerdifyReviews';
import Privacy from './pages/Privacy';
import Refunds from './pages/Refunds';
import ReportAbuse from './pages/ReportAbuse';
import Terms from './pages/Terms';
import TopNerds from './pages/TopNerds';

import ThankYou from './pages/ThankYou';
import NotFound from './pages/NotFound';

import { IS_PROD } from '../../config';

import '../../themes/nerdify/assets/css/index.css';

export const viewport = 'width=device-width, initial-scale=1';
const dynamicHeadline = false;

export const favicons = [
  {
    type: 'image/x-icon',
    sizes: false,
    href: '/img/nerdifyit/favicon.ico',
  },
  {
    type: 'image/png',
    sizes: '16x16',
    href: '/img/nerdifyit/favicon-16x16.png',
  },
  {
    type: 'image/png',
    sizes: '32x32',
    href: '/img/nerdifyit/favicon-32x32.png',
  },
  {
    type: 'image/png',
    sizes: '96x96',
    href: '/img/nerdifyit/favicon-96x96.png',
  },
  {
    type: 'image/png',
    sizes: '192x192',
    href: '/img/nerdifyit/favicon-192x192.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '57x57',
    href: '/img/nerdifyit/apple-icon-57x57.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '60x60',
    href: '/img/nerdifyit/apple-icon-60x60.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '72x72',
    href: '/img/nerdifyit/apple-icon-72x72.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '76x76',
    href: '/img/nerdifyit/apple-icon-76x76.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '114x114',
    href: '/img/nerdifyit/apple-icon-114x114.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '120x120',
    href: '/img/nerdifyit/apple-icon-120x120.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '144x144',
    href: '/img/nerdifyit/apple-icon-144x144.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '152x152',
    href: '/img/nerdifyit/apple-icon-152x152.png',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/img/nerdifyit/apple-icon-180x180.png',
  },
];

export const routes = [
  {
    path: '/about',
    meta: {
      title: 'Nerdify | About Us - Nerdifyit.com',
      description:
        'Nerdify is a chat-based platform where freelance Nerds help businesses, students & academia with tutoring, coaching, mentoring 24/7',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: About,
    pageCfg: {
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
    },
  },
  {
    path: '/become',
    meta: {
      title: 'Nerdify | Become a Nerd',
      description:
        'Interested in becoming a Nerd? Submit an application form, pass the examination, get approved & get started!',
      keywords:
        'nerds, best nerds, find a nerd, on-demand research, data for analysis, business presentation, find a tutor, college advisor, market research, study help, find internship, research, presentation, college, university',
      robots: 'noindex, nofollow',
    },
    component: Become,
    pageCfg: {},
  },
  {
    path: '/cookies',
    meta: {
      title: 'Nerdify | Cookies',
      description:
        'This section will help you better understand what is cookies and how Nerdify and our partners use them.',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Cookies,
    pageCfg: {},
  },
  {
    path: '/honor-code',
    meta: {
      title: 'Nerdify | Honor Code - Nerdifyit.com',
      description:
        'The Honor Code is a policy promoting Nerdify Platform usage integrity.',
      keywords: '',
      robots: 'noindex, nofollow',
      social: [
        { property: 'og:description', content: 'Nerdify is a chat-based platform where skilled freelance Nerds help businesses, students & academia with tutoring, coaching, mentoring & more' },
        { name: 'twitter:description', content: 'Nerdify is a chat-based platform where skilled freelance Nerds help businesses, students & academia with tutoring, coaching, mentoring & more' },
      ],
    },
    component: HonorCode,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/how-it-works',
    meta: {
      title: 'Nerdify | How it works - Nerdifyit.com',
      description:
        'Nerdify is a chat-based platform where freelance Nerds help businesses, students & academia with tutoring, coaching, mentoring 24/7',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: HowItWorks,
    exact: true,
    pageCfg: {
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      disclaimers: [
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
    },
  },
  {
    path: '/maintenance',
    meta: {
      title: '',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Maintenance,
    pageCfg: {},
  },
  {
    path: '/top-nerds',
    meta: {
      title: 'Nerdify | Top Nerd Tutors - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: TopNerds,
    exact: true,
    pageCfg: {
      disclaimers: [
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
    },
  },
  {
    path: '/refunds',
    meta: {
      title: 'Nerdify | Refund policy - Nerdifyit.com',
      description:
        'If the received service did not meet your initial requirements - let us know ASAP.',
      keywords: '',
      robots: 'noindex, nofollow',
      social: [
        { property: 'og:description', content: 'Nerdify is a chat-based platform where skilled freelance Nerds help businesses, students & academia with tutoring, coaching, mentoring & more' },
        { name: 'twitter:description', content: 'Nerdify is a chat-based platform where skilled freelance Nerds help businesses, students & academia with tutoring, coaching, mentoring & more' },
      ],
    },
    component: Refunds,
    pageCfg: {},
  },
  {
    path: '/terms-and-conditions',
    meta: {
      title: 'Nerdify | Terms and conditions',
      description:
        'By using Nerdify the User has agreed with Terms & Conditions, applicable laws and regulations, and is responsible for compliance with them.',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Terms,
    pageCfg: {},
  },
  {
    path: '/privacy-policy',
    meta: {
      title: 'Nerdify | Privacy Policy',
      description:
        'This section will help you better understand how Nerdify collects, stores, processes and uses your personal information. ',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Privacy,
    pageCfg: {},
  },
  {
    path: '/report-abuse',
    meta: {
      title: 'Nerdify | Report abuse',
      description:
        'If you believe that the Nerd assigned to your task agreed to provide unethical help, please, report abuse.',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: ReportAbuse,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/',
    meta: {
      title:
        'Nerdify | #1 On-Demand Help for Businesses, Students & Academia 24/7 - Nerdifyit.com',
      description:
        'Nerdify is a chat-based platform where freelance Nerds help businesses, students & academia with tutoring, coaching, mentoring 24/7',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      AntiFlicker: 4,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: 'Get instant access to Nerd Tutors',
      whatIsNerdify: <Text>Nerdify is a chat-based platform where freelance Nerds help businesses, students,<br className="desktop-only" /> and academia with tutoring, coaching, mentoring, and the like</Text>,
      hiw: [
        {
          img: 'hiw1',
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          img: 'hiw2',
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          img: 'hiw3',
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      footer: {
        loveText: "Nerdify. Made with love."
      },
      hasNav: true,
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_main_en',
        },
      }),
    },
  },
  {
    path: '/students-1',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,

      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },

      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp1_en',
        },
      }),
    },
  },
  {
    path: '/students-2',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,

      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },

      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp2_en',
        },
      }),
    },
  },
  {
    path: '/students-3',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/students-4',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp4_en',
        },
      }),
    },
  },
  {
    path: '/students-5',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
      social: [
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'Nerdify' },
        { property: 'og:title', content: 'Nerds at your fingertips 24/7 to help with on-demand tasks' },
        { property: 'og:description', content: 'Nerdify is a chat-based platform where skilled freelance Nerds help businesses, students & academia with tutoring, coaching, mentoring & more' },
        { property: 'og:url', content: 'https://nerdifyit.com/' },
        { property: 'og:image', content: 'https://nerdifyit.com/Nerdifyit_banner.png' },
        { property: 'og:image:type', content: 'image/png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:image:alt', content: 'Get help with urgent tasks at Nerdify - http://Nerdifyit.com' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: 'Nerds at your fingertips 24/7 to help with on-demand tasks' },
        { name: 'twitter:description', content: 'Nerdify is a chat-based platform where skilled freelance Nerds help businesses, students & academia with tutoring, coaching, mentoring & more' },
        { name: 'twitter:image', content: 'https://nerdifyit.com/Nerdifyit_banner.png' }
      ],
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      AntiFlicker: 3,
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp5_en',
        },
      }),
    },
  },
  {
    path: '/students-6',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp6_en',
        },
      }),
    },
  },
  {
    path: '/business-help',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      AntiFlicker: 1,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/social-sciences-help',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      AntiFlicker: 4,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/natural-sciences-help',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      AntiFlicker: 4,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/statistics-help',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      AntiFlicker: 4,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/finance-accounting-help',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      AntiFlicker: 1,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/humanities-help',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      AntiFlicker: 1,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/english-literature-help',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      AntiFlicker: 1,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp3_en',
        },
      }),
    },
  },
  {
    path: '/tt-students-1',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com_lp_tt',
        },
      }),
    },
  },
  {
    path: '/math-help',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      AntiFlicker: 1,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
    },
  },
  {
    path: '/rt-students',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
    },
  },
  {
    path: '/rt-students-lreact',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      promoBanner: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: 'Get $40 Off Your 1st Tutoring Session',
      hasNav: true,
      whatIsNerdify: <Text>Nerdify is a chat-based platform where business represantatives can get a Nerd to<br className="desktop-only" /> help with research, data collection, and other business chores!</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
    },
  },
  {
    path: '/study-help',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      hasNav: true,
      isRedirectToThankYou: true,
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
      ...(IS_PROD && {
        landingGuid: {
          us: 'nerdifyit.com3_en',
        },
      }),
    },
  },
  {
    path: '/nerdify-reviews',
    meta: {
      title: 'Nerdify | Updated Nerdify Reviews 2025 - Nerdifyit.com',
      description:
        'Check out the latest Nerdify Reviews. Share your feedback with us!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: NerdifyReviews,
    exact: true,
    pageCfg: {
      hasNav: true,
    },
  },
  {
    path: '/for-students',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        'Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: Landing7,
    exact: true,
    pageCfg: {
      headline: 'Overwhelmed by urgent tasks?',
      subtitle: <Text>Get instant access to<br className="mobile-only" /> Nerd Tutors</Text>,
      hiw: [
        {
          content: <Text>Tell us what subject you need tutoring help with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a tutoring session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get helpful learning insights and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'In last 24hrs our Nerds had tutoring sessions on 300+ topics',
        items: [
          {
            title: 'Tutoring',
            msg: 'Can someone explain to me the concept of confidence interval today?'
          },
          {
            title: 'Tutor in Statistics',
            msg: "I don't understand inferential statistics methods. Can u explain me the methodology?"
          },
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Binomial Distribution',
            msg: "In order to master the topic I need to understand Binomial Distribution. Can you explain it in simple words?"
          },
          {
            title: 'Tutor in Sociology',
            msg: "Please clarify the concept of Manifest Function so that I could understand my assignment in sociology."
          },
          {
            title: 'English Tutor',
            msg: "I'm very bad in writing, help me find an English tutor to improve my skills, please."
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
    },
  },
  {
    path: '/for-businesses',
    meta: {
      title: 'Nerdify | #1 On-Demand Help for Businesses, Students & Academia 24/7 - Nerdifyit.com',
      description:
        'Nerdify is a chat-based platform where freelance Nerds help businesses, students & academia with tutoring, coaching, mentoring 24/7',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: LandingForBusinesses,
    exact: true,
    pageCfg: {
      subtitle: 'Get instant access to Nerd Tutors',
      whatIsNerdify: <Text>Nerdify is a chat-based platform where business represantatives can get a Nerd to<br className="desktop-only" /> help with research, data collection, and other business chores!</Text>,
      hiw: [
        {
          content: <Text>Tell us what topic you need assistance with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get reliable support and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'Our Nerds have 150+ business sessions daily',
        items: [
          {
            title: 'Market research',
            msg: "I need assistance with a barbershop services research in Nebraska with a detailed study of the competitive landscape."
          },
          {
            title: 'Elevator speech',
            msg: "Can someone practice with me my elevator speech for my startup? It should cover all benefits and highlight growth potential for investors."
          },
          {
            title: 'Competitors analysis',
            msg: "Could you assist me with a competitor analysis for my online shop? I need a full info on assortment, shipping, price, etc."
          },
          {
            title: 'Advise on a plan',
            msg: "I want someone to go through my monthly content plan for social media for my brand."
          },
          {
            title: 'Presentation slides',
            msg: "I got stuck picking the right format for my presentation, can someone advise me on the framework?"
          },
          {
            title: 'Survey results',
            msg: "I need support organizing my survey results and transferring them to visuals. Can you explain how to do that?"
          },
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
    },
  },
  {
    path: '/for-academia',
    meta: {
      title: 'Nerdify | #1 On-Demand Help for Businesses, Students & Academia 24/7 - Nerdifyit.com',
      description:
        'Nerdify is a chat-based platform where freelance Nerds help businesses, students & academia with tutoring, coaching, mentoring 24/7',
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: LandingForAcademia,
    exact: true,
    pageCfg: {
      // demoCards: 'for_academia',

      subtitle: 'Get instant access to Nerd Tutors',
      whatIsNerdify: <Text>Let Nerdify find you freelance Nerds who can help with on-demand tutoring,<br className="desktop-only" /> research mentoring, data collection, and other time-consuming tasks!</Text>,
      hiw: [
        {
          content: <Text>Tell us what topic you need assistance with</Text>,
        },
        {
          content: <Text>Get the best-fit Nerd Tutor to explain you the topic as soon as possible</Text>,
        },
        {
          content: <Text>Schedule a session and make a secure payment through the platform</Text>,
        },
        {
          img: 'hiw21',
          content: <Text>Get reliable support and become one more happy Nerdify client</Text>,
        },
      ],
      disclaimers: [
        <Text>Nerds can do anything to help with your college or work life, as long as it's legal and doesn't violate any college, university, corporate policy or our <Link className="link" to="/honor-code">Honor Code</Link>.<br className="desktop-only" /> See <Link className="link" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</Text>,
        <Text>Please be aware that Nerds will not agree to be engaged in the activity that violates any college, university, corporate policy or our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>. Please read our Honor Code and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</Text>,
      ],
      saveTime: {
        "nay": {
          "1": "No lengthy search for the right tutor",
          "2": "No long negotiations",
        },
        "yea": {
          "0": "Instant reply",
          "1": "Just tell us your subject and topic",
        },
      },
      topNerds: {
        texts: {
          title: "Our Nerd Tutors are among the TOP 2% in their fields",
          subtitle: <Text>They all are freelancers with proven knowledge and vast experience in their area, who passed a rigid examination process and agreed to follow our <NavLink className="link" to="/honor-code">Honor Code</NavLink> when joining Nerdify platform.</Text>,
          cta: "Meet the Nerd Tutors",
          apply: "Apply to be a Nerd Tutor",
        },
        isWide: true,
      },
      demoSubjects: {
        title: <Text>Our Nerd Tutors graduated in <br className="mobile-only" /><span className="strong">75+ different subjects</span>...</Text>,
      },
      reviews: {
        title: 'Every day Nerd Tutors have over 200 sessions!',
        items: [
          {
            title: 'Find materials',
            msg: "I need a list of recently published articles on metal fatigue in aircraft components. Can you advise some?"
          },
          {
            title: 'Find science magazine',
            msg: "I need to have the list of Astrophysics Magazines where I can publish results of my comet trajectory research."
          },
          {
            title: 'Reliable sources',
            msg: "I need a list of reliable articles on findings in Social Psychology. Can you suggest some?"
          },
          {
            title: 'Consultation',
            msg: "I want to conduct an email survey for my study. I need someone to advise me how to find people who will agree to participate."
          },
          {
            title: 'Survey',
            msg: "I need help with structuring my survey. Can someone assist with that?"
          },
          {
            title: 'Speech practice',
            msg: "I want to practice my speech. Can someone listen to it and suggest improvements?"
          }
        ]
      },
      sliderCombined: {
        title: <Text>Not sure what to start with?<br className="desktop-only" /> Take a look at the most popular topics!</Text>,
      },
      textback: {
        title: <Text>Let us find you the right Nerd Tutor.<br /> Leave your number and we'll text you back in 10 sec</Text>,
      },
      prices: {
        content: "You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat.",
      },
    },
  },

  // redirections

  { path: '/business-1', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-english-literature-help', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-social-sciences-help', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-business-help', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-finance-accounting-help', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-humanities-help', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-math-help', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-natural-sciences-help', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-statistics-help', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-students-1', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-students-2', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-students-3', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-students-4', pageCfg: { redirectTo: '/', }, },
  { path: '/cbl-students-5', pageCfg: { redirectTo: '/', }, },
  { path: '/clp1-students-1', pageCfg: { redirectTo: '/', }, },
  { path: '/clp1-students-2', pageCfg: { redirectTo: '/', }, },
  { path: '/clp1-students-3', pageCfg: { redirectTo: '/', }, },
  { path: '/clp1-students-4', pageCfg: { redirectTo: '/', }, },
  { path: '/clp1-students-5', pageCfg: { redirectTo: '/', }, },
  { path: '/clp2-students-1', pageCfg: { redirectTo: '/', }, },
  { path: '/clp2-students-2', pageCfg: { redirectTo: '/', }, },
  { path: '/clp2-students-3', pageCfg: { redirectTo: '/', }, },
  { path: '/clp2-students-4', pageCfg: { redirectTo: '/', }, },
  { path: '/clp2-students-5', pageCfg: { redirectTo: '/', }, },
  { path: '/clp3-math-open-hours', pageCfg: { redirectTo: '/', }, },
  { path: '/clp3-students-1', pageCfg: { redirectTo: '/', }, },
  { path: '/clp3-students-2', pageCfg: { redirectTo: '/', }, },
  { path: '/clp3-students-3', pageCfg: { redirectTo: '/', }, },
  { path: '/clp3-students-3-maths', pageCfg: { redirectTo: '/', }, },
  { path: '/clp3-students-4', pageCfg: { redirectTo: '/', }, },
  { path: '/clp3-students-5', pageCfg: { redirectTo: '/', }, },
  { path: '/clp4-students-1', pageCfg: { redirectTo: '/', }, },
  { path: '/clp4-students-2', pageCfg: { redirectTo: '/', }, },
  { path: '/clp4-students-3', pageCfg: { redirectTo: '/', }, },
  { path: '/clp4-students-4', pageCfg: { redirectTo: '/', }, },
  { path: '/clp4-students-5', pageCfg: { redirectTo: '/', }, },
  { path: '/college-1', pageCfg: { redirectTo: '/', }, },
  { path: '/for-colleges', pageCfg: { redirectTo: '/', }, },
  { path: '/math-tutoring', pageCfg: { redirectTo: '/', }, },
  { path: '/mathon', pageCfg: { redirectTo: '/', }, },
  { path: '/mathon/lp1', pageCfg: { redirectTo: '/', }, },
  { path: '/mathon/privacy-policy', pageCfg: { redirectTo: '/', }, },
  { path: '/mathon/terms-and-conditions', pageCfg: { redirectTo: '/', }, },
  { path: '/mathon/thankyou', pageCfg: { redirectTo: '/', }, },
  { path: '/nerdybot', pageCfg: { redirectTo: '/', }, },
  { path: '/students-1-short', pageCfg: { redirectTo: '/', }, },
  { path: '/tr', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-business-help', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-english-literature-help', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-finance-accounting-help', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-humanities-help', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-math-help', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-natural-sciences-help', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-social-sciences-help', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-statistics-help', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-students-1', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-students-2', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-students-3', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-students-4', pageCfg: { redirectTo: '/', }, },
  { path: '/tr-students-5', pageCfg: { redirectTo: '/', }, },
  { path: '/video-example', pageCfg: { redirectTo: '/', }, },
  { path: '/video-explanations', pageCfg: { redirectTo: '/', }, },
  { path: '/video-tutoring', pageCfg: { redirectTo: '/', }, },

  {
    path: '/thank-you',
    meta: {
      title: 'Nerdify | #1 On-Demand Learning Help for Students 24/7 - Nerdifyit.com',
      description:
        "Power of 7,360+ Nerd Tutors at your fingertips 24/7🤓. Nerds rated 4.7/5⭐️. Get tutoring help in any subject. Instant reply📱Text us & try Nerdify!",
      keywords:
        'Nerdify, nerdifyit, learning help, study help, tutoring help, on-demand tutoring, find a tutor, Math tutor, History tutor, English tutor, Statistics tutor, writing tutor, essay tutor, paper tutor, homework tutor, assignment tutor, nerd tutors, college tutor, university tutor, business, academia, research coaching, research mentor, presentation consulting',
      robots: 'noindex, nofollow',
    },
    component: ThankYou,
    exact: true,
    pageCfg: {
      // demoCards: 'main_reviews',
      backLink: true,
    },
  },

  {
    meta: {
      title: 'Nerdify | Error',
      description: 'Nerdify | Error page',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: NotFound,
    pageCfg: {},
  },
];
