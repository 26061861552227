import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Holder from '../../../../themes/nerdify/components/Holder';

import {
  Header1,
  HeaderIcon,
} from './../../../../themes/nerdify/components/Header';
import { OurPrices2 } from '../../../../themes/nerdify/components/OurPrices';
import Text from '../../../../base_components/Text';
import { Section14 } from '../../../../themes/nerdify/components/Section';

import { Hero14 } from '../../../../themes/nerdify/components/Hero';
import Reviews from '../../blocks/Reviews';
import Partners, {
  PartnersList,
  PartnersItem,
} from '../../../../themes/nerdify/components/Partners';
import FeedbackBlock from '../../../../themes/nerdify/components/FeedbackBlock';

import { Footer2 } from '../../blocks/Footer';
import Press, {
  PressList,
  PressItem,
  PressIcon,
} from '../../../../themes/nerdify/components/Press';
import Icon from '../../../../themes/nerdify/components/Icons';
import { TextBack5 } from '../../blocks/TextBack';
import { useMobile } from '../../../../hooks';
import { trackValues } from '../../../../lrtracker';

import LocalesPage from '../LocalesPage';

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile,
  });
};

class LandingNerdifyReviews extends Component {
  constructor(props) {
    super(props);
    this.openHiwModal = this.openHiwModal.bind(this);
    this.closeHiwModal = this.closeHiwModal.bind(this);
  }

  componentDidMount() {
    trackValues('stage', 'landing-reviews', { context: {} });
  }

  openHiwModal() {
    this.howItWorksModalRef.show();
  }

  closeHiwModal() {
    this.howItWorksModalRef.hide();
  }

  render() {
    const { pageCfg } = this.props;

    return (
      <WithHook>
        {({ isMobile }) => {
          return (
            <LocalesPage {...this.props}>
              <div className="landing">
                <Header1>
                  <Holder>
                    <NavLink className="logo-link logo-link_1" to="/">
                      <HeaderIcon iconName="logo-dark" theme={1} />
                    </NavLink>
                  </Holder>
                </Header1>

                <Hero14>
                  <div className="hero-main">
                    <div className="holder">
                      <h1 className="hero__title">
                        {pageCfg.headline || (
                          <Text>Searching for Nerdify reviews? </Text>
                        )}
                      </h1>
                      <h2 className="hero__subtitle">
                        {pageCfg.subtitle || (
                          <Text>
                            Check out the latest reviews from
                            <br className="mobile-only" /> our users on all
                            review platforms!
                          </Text>
                        )}
                      </h2>
                      <div className="rate-img" />
                      <h2 className="rate-title">
                        76,750 users rated Nerds 4.7/5
                      </h2>
                      <ScrollLink
                        to="feedback-block"
                        smooth={true}
                        duration={500}
                        className="button__type15"
                      >
                        LEAVE FEEDBACK
                      </ScrollLink>

                      <ScrollLink
                        to="review-block"
                        smooth={true}
                        //offset={-70}
                        duration={500}
                        className="scroll-down-btn"
                        type="button"
                      >
                        <Icon
                          className="scroll-down-img"
                          iconName="mouse-shape"
                        />
                        <span className="scroll-down-message">
                          Read reviews
                        </span>
                      </ScrollLink>
                    </div>
                  </div>
                </Hero14>

                <Reviews />

                <Section14>
                  <div className="holder">
                    <h2 className="h3">
                      Ready to become one
                      <br className="mobile-only" /> more happy Nerdify client?
                    </h2>
                    <TextBack5 {...this.props} submitBtnTxt={'Text me now'} />
                  </div>
                </Section14>

                <OurPrices2 descr="You only pay for the time the Nerd tutor spends working with you during the tutoring session: the platform fee (11%) is already included! If this is your first time using Nerdify, you qualify for a guaranteed welcome discount. Ask for more details in chat."/>

                <Partners className={'partners_1-1'}>
                  <div className="partners_title">Our Partners </div>
                  <PartnersList>
                    <PartnersItem />
                    <PartnersItem />
                    <PartnersItem />
                    <PartnersItem />
                    <PartnersItem />
                  </PartnersList>
                </Partners>

                <FeedbackBlock className={'feedback-block'} />

                <Press className={'press__3'}>
                  <div className="press-wrapper">
                    <div className="press__title">Press about us</div>
                    <PressList>
                      <PressItem>
                        <PressIcon iconName={'forbes'} />
                      </PressItem>
                      <PressItem>
                        <a
                          className="press__link"
                          href="https://venturebeat.com/2017/05/19/nerdify-announces-the-launch-of-nerdy-bot/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PressIcon iconName={'venturebeat'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          className="press__link"
                          href="https://tech.co/startups-redefining-educational-space-2017-04"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PressIcon iconName={'tech-co'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          className="press__link"
                          href="https://www.nextmedia-hamburg.de/wp-content/uploads/2018/12/nextMedia.Kompass_Trendreport_Oktober_2016.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PressIcon iconName={'next-media'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          className="press__link"
                          href="https://nulltx.com/nerdify-fills-the-gaps-in-education/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PressIcon iconName={'merkle'} />
                        </a>
                      </PressItem>
                    </PressList>
                  </div>
                </Press>

                <Footer2 />
              </div>
            </LocalesPage>
          );
        }}
      </WithHook>
    );
  }
}

export default LandingNerdifyReviews;
